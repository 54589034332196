<template>
  <v-container>
    <v-row align="center" justify="space-around">
      <v-card class="pa-8" min-width="70%">
        <v-card-title class="pa-5 pb-8">
          <div class="text-title">Profile</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                label="Username"
                v-model="username"
                readonly
                :color="colorTheme.textField"
                disabled
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                label="Email"
                v-model="email"
                readonly
                :color="colorTheme.textField"
                disabled
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                label="Organization"
                v-model="organization"
                readonly
                :color="colorTheme.textField"
                disabled
              ></v-text-field>
            </v-row>
            <v-row class="pt-8">
              <v-btn
                @click="changePassword"
                tile
                large
                min-width="100"
                :color="colorTheme.button"
                class="py-4 px-10 white--text"
              >
                Change password
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("auth", ["user"]),
    username() {
      if (this.user) {
        return this.user.username;
      }
      return "";
    },
    email() {
      if (this.user) {
        return this.user.email;
      }
      return "";
    },
    organization() {
      if (this.user) {
        //return this.user.attributes["custom:organization"];
        return this.user.organization;
      }
      return "";
    }
  },
  methods: {
    changePassword() {
      this.$router.push("/profile/change-password");
    }
  }
};
</script>
